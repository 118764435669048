
.head-content {
    display: flex;
    justify-content: space-between;

    .head-left-title {
        margin-right: 10px;
        font-size: 16px;
        color: #333333;
    }
}

::v-deep .el-input-number .el-input__inner {
    text-align: left;
}

.customTable {
    .el-input-number {
        width: 120px;
    }

    .wave-box {
        display: flex;
        justify-content: center;
        align-items: center;

        .el-input-number {
            width: 100px;
        }

        .divide-line {
            width: 14px;
            height: 2px;
            background: #BFBFBF;
            margin: 0 4px;
        }

        span {
            margin-left: 6px;
        }
    }
}

.setting-box {
    margin-top: 40px;
}

.content-title {
    position: relative;
    padding-left: 14px;
    margin-bottom: 22px;
    font-size: 16px;

    &:before {
        content: '';
        width: 4px;
        height: 16px;
        background-color: #2DC079;
        border-radius: 2px;
        position: absolute;
        left: 0;
        top: 3px;
    }
}

.item-content {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 16px;

    .even-content {
        display: flex;
        align-items: center;
        width: 100%;
    }

    .odd-content {
        display: flex;
        width: 100%;
        align-items: center;
        margin-top: 10px;

        .odd-item {
            display: flex;
            align-items: center;
            margin-left: 40px;
            width: 200px;

            &:nth-of-type(2) {
                margin-left: 0;
            }

            .el-input-number {
                margin-left: 0;
            }
        }
    }

    .item-title {
        width: 120px;
        text-align: right;
        margin-right: 10px;
        font-size: 16px;
        color: #333333;
    }

    .divide {
        width: 15px;
        height: 2px;
        background: #D2D2D2;
        margin: 0 4px;
    }

    .el-input-number {
        margin-left: 40px;

        &:nth-of-type(2) {
            margin-left: 0;
        }
    }

    .item-content-title {
        width: 200px;
        margin-left: 40px;
        text-align: center;
        font-size: 16px;
        color: #333333;

        &:nth-of-type(2) {
            margin-left: 0;
        }
    }
}

.item-input {
    &.max {
        width: 200px;
    }

    &.medium {
        width: 182px;
    }

    &.mini {
        width: 70px;
    }
}

.input-text {
    margin: 0 2px;
}

.vertical-content {
    display: flex;
    flex-wrap: wrap;

    .vertical-item {
        margin-left: 40px;
        margin-top: 20px;
        display: flex;

        &:nth-of-type(6n + 1) {
            margin-left: 0;
        }

        .left-box {
            width: 120px;
            margin-right: 10px;


            .inner-item {
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                font-size: 16px;
                color: #333333;

                &:first-child {
                    margin-top: 26px;
                }
            }
        }


        .right-box {
            width: 200px;
        }

        .inner-item {
            margin-top: 20px;
        }
    }

    .item-title {
        width: 200px;
        text-align: center;
        font-size: 16px;
        color: #333333;
        margin-bottom: 10px;
    }

}
