
::v-deep .el-form-item.is-required:not(.is-no-asterisk) > .el-form-item__label:before {
    content: '';
}

::v-deep .el-input-number .el-input__inner {
    text-align: left;
}

.form-item {
    display: flex;
    align-items: center;
    width: 200px;

    .input-text {
        margin: 0 2px;
    }

}

.divide {
    width: 15px;
    height: 2px;
    background: #D2D2D2;
    margin: 0 4px;
}

.item-input {
    &.max {
        width: 200px;
    }

    &.medium {
        width: 182px;
    }

    &.mini {
        width: 70px;
    }
}

.content-title {
    position: relative;
    padding-left: 14px;
    margin-bottom: 22px;
    font-size: 16px;

    &:before {
        content: '';
        width: 4px;
        height: 16px;
        background-color: #2DC079;
        border-radius: 2px;
        position: absolute;
        left: 0;
        top: 3px;
    }
}

.vertical-content {
    display: flex;
    flex-wrap: wrap;

    .vertical-item {
        margin-left: 40px;
        margin-top: 20px;
        display: flex;

        &:nth-of-type(6n + 1) {
            margin-left: 0;
        }

        .left-box {
            width: 120px;
            margin-right: 10px;


            .inner-item {
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                font-size: 16px;
                color: #333333;

                &:first-child {
                    margin-top: 26px;
                }


                .divide {
                    width: 15px;
                    height: 2px;
                    background: #D2D2D2;
                    margin: 0 4px;
                }

                .input-text {
                    margin: 0 2px;
                }
            }
        }


        .right-box {
            width: 200px;

            .form-item {
                margin-top: 20px;
            }
        }

        .inner-item {
            margin-top: 20px;
        }
    }

    .item-title {
        width: 200px;
        text-align: center;
        font-size: 16px;
        color: #333333;
        margin-bottom: 10px;
    }

}
