
.progress-modal {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255,255,255, 0.7);
    .progress-text {
        font-size: 24px;
        color: #2DC079;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .notice {
            font-size: 14px;
        }
    }
}
.add-customer-service {
    box-sizing: border-box;
    height: 100%;
    display: flex;
    flex-direction: column;

    ::v-deep .el-scrollbar__wrap {
        overflow-x: hidden;

        .el-scrollbar__view {
            padding: 0px 20px;
        }
    }

    .dialog-footer {
        text-align: center;
        margin: 100px 0 50px;
    }

    .creating-success {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 120px;

        .success-img {
            width: 240px;
            height: 240px;

            img {
                max-width: 100%;
                max-height: 100%;
            }
        }

        span {
            color: #333333;
            font-size: 24px;
            line-height: 1;
            margin: 32px 0 36px;
        }
    }
}

